.register .option {
    text-align: center;
    font-size: 1.5em;
    padding: 5px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    color: #545454;
    height: 100%;
    min-height: 130px;
}

.register .box-content {
    padding-top: 10%;
    font-weight: 300;
}

.register .option:hover {
    background-color: #eef6f9;
}

.register .option.selected {
    border: 2px solid #226d77;
    color: #226d77;
}

.register .MuiTextField-root {
    margin-bottom: 30px;
    width: 100%;
}

/* google btn */
.register .google-btn {
    width: 100%
}

/* email sign up btn */
.register .email-btn {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    width: 100%;
    margin-bottom: 30px;
    cursor: pointer;
}

.register .email-btn > .email-icon-container { 
    margin-right: 10px;
    background: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 2px;
}

.register .email-btn > .email-text-container { 
    padding: 10px 10px 10px 0px;
    font-weight: 500;
}