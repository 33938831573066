.pie-chart {
  width: 50%;
  margin: auto;
}

.tile-container {
  min-height: 100px;
  text-decoration: none;
}

.tile {
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  height: 100%;
  font-size: 1.2em;
  height: 250px;
  margin: 15px;
}

.blue-tile {
  color: #226d77;
  border-radius: 8px;
}

.pink-tile {
  color: #226d77;
  border-radius: 8px;
}

/*.tile-page {
  gap: 10px 20px;
}*/

.tile-page h3 {
  padding-left: 15px;
  font-weight: 400;
  font-size: 1.5em;
  padding: 15px;
  margin-top: 190px;
}

/* carosal colors */
.rec-arrow {
  background-color: white !important;
}

.rec-arrow:focus, .rec-arrow:focus:enabled, .rec-arrow:hover {
  background-color: #8cc5be !important;
}

.rec-dot_active {
  background-color: #226d77 !important;
  box-shadow: 0 0 1px 3px #8cc5be !important;
}

/* mobile responsive */
@media screen and (max-width: 992px) {
  .journey-main {
    display: block;
  }

  .card-header {
    text-align: center;
    display: block;
    width: 100%;
  }

  .pie-chart {
    width: 25%;
  }
}