.logo-container {
    flex-grow: 1;
    max-width: 150px;
}

.header-logo {
    max-width: 100px;
}

.mobile-menu {
    display: none !important;
}

.nav-wrapper {
    display: flex;
    margin: auto;
}

.nav-item-wrapper {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 150px;
    text-align: center;
}

.nav-item-wrapper > a {
    padding: 10px;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #226d77;
    border-bottom: 3px solid transparent;
}

.nav-item-wrapper > .active {
    color: #dc9577;
    border-bottom: 3px solid transparent;
}

.nav-item-wrapper > a:hover {
    color: #dc9577;
    border-bottom: 3px solid transparent;
}

.MuiMenuItem-root {
    display: block;
}

/*.MuiToolbar-root {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
}*/

/* mobile responsive */
@media screen and (max-width: 992px) {
    .logo-container {
        margin: auto;
    }

    .mobile-menu { 
        display: inline-flex !important;
    }

    .nav-wrapper { 
        display: none !important;
    }

    /*.MuiToolbar-root {
        width: 100%;
        margin: initial;
    }*/
}