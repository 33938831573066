.unauthorized {
    margin: -15px;
}

.welcome-title {
    position: absolute;
    font-weight: bold;
    padding: 10px;
    margin-left: 10px;
    color: #226d77;
    width: 600px;
    font-size: 3em;
}

.welcome-content {
    position: absolute;
    bottom: 35%;
    text-align: center;
    width: 600px;
}

.secondary-box {
    background-color: #fcddd2;
    padding: 45px 0;
}

.secondary-box > .col-6 > h1 {
    margin-top: 0px;
}

.subtext {
    font-weight: 300;
    font-size: 22px;
    color: black;
}

.avatars {
    vertical-align: middle;
}

.avatar-container {
    display: inline-grid;
    font-size: 14px;
    text-align: center;
    width: 105px;
    margin-bottom: 30px;
}

.avatar-container > div {
    left: 25%;
}

/*responsive design*/
@media screen and (max-width: 1495px) {
    .welcome-title {
        width: 400px;
    }

    .welcome-content {
        width: 400px;
        bottom: 15%;
    }
}

@media screen and (max-width: 1300px) {
    .welcome-title {
        width: 300px;
        font-size: 2em;
    }

    .welcome-content {
        width: 300px;
        bottom: 10%;
    }
}

@media screen and (max-width: 960px) {
    .welcome-title {
        position: initial;
        text-align: center;
        max-width: 100%;
        font-size: 2em;
        width: 100%
    }

    .welcome-content {
        position: initial;
        background-color: #fcddd2;
        margin-left: 0;
        margin-top: -5px;
        padding: 15px 0;
        width: 100%;
    }

    .avatar-container {
        margin: 5px;
    }

    .secondary-box {
        text-align: center
    }

    .secondary-box > .col-6 > h1 {
        margin-top: 30px;
    }
}