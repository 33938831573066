.locked-content {
    background-color: #eef6f9;
    padding: 25px;
    text-align: center;
    margin-left: -15px !important;
    margin-right: -15px !important;
    box-shadow: 2px -1px 2px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.unauth-list .view-row:hover {
    background-color: white;
}

.icons-highlights {
    border: 2px solid;
    border-radius: 30px;
    padding: 10px;
}

.icons-text {
    color: #dc9577;
    font-weight: 500;
    font-size: 1.25em;
}

.icons-subtext {
    font-weight: 300;
    font-size: 1.25em;
}